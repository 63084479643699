import AskServiceModal from "../ask-service-modal";
import React from "react";

let ServiceItem = ({ language, data }) => {
    const [modalShow, setModalShow] = React.useState(false);
    
    return (
        <>
            <div className="service-item" >
                <div className="service-item-icon">
                    <img src={`/images/icons/${data.icon}`} width={60} alt={data.title} />
                </div>
                <div className="service-item-header">
                    {data.title}
                </div>
                <div className="service-item-text">
                    {data.text}
                </div>
                <button type="button" className="service-item-read-more" onClick={() => setModalShow(true)}>{language.askForService}</button>
            </div>
            <AskServiceModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
    )
}
export default ServiceItem;