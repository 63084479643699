import ALink from "~/components/features/alink";

let AboutUs = ({ language }) => {
    const aboutItems = [
        {
            icon: "sustainability.svg",
            title: language.sustainability,
            text: language.sustainabilityText
        },
        {
            icon: "safety.svg",
            title: language.safety,
            text: language.safetyText
        },
        {
            icon: "integrity.svg",
            title: language.integrity,
            text: language.integrityText
        },
        {
            icon: "innovation.svg",
            title: language.innovation,
            text: language.innovationText
        },
        {
            icon: "responsibility.svg",
            title: language.responsibility,
            text: language.responsibilityText
        },
        {
            icon: "collaboration.svg",
            title: language.collaboration,
            text: language.collaborationText
        },
        {
            icon: "respect.svg",
            title: language.respect,
            text: language.respectText
        }
    ]
    return (
        <section className="about-us" id="about-us">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="section2-title">{language.aboutUs}</div>
                        <div className="section2-header">{language.aboutUsTitle}</div>
                        <div className="section2-text">{language.aboutUsHeader}</div>
                        <ALink href="/about-us" className="about-us-read-more">{language.readMore}</ALink>
                        <div>
                            <img src="images/about-img.png" className="img-fluid about-us-img" style={{ position: 'relative', zIndex: 100 }} alt="about-us-img" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 about-us-right-side">
                        <div className="position-relative w-100">
                            <img className="position-absolute end-0 top-0" width={200} src="/images/our-products-img.svg" alt="our-products-img" />
                        </div>
                        <div className="about-us-right-side-items">
                            {
                                aboutItems.map((x, y) =>
                                    <div className="about-us-right-side-item" key={y}>
                                        <div>
                                            <img src={`/images/icons/${x.icon}`} />
                                        </div>
                                        <div>
                                            <div className="about-us-right-side-item-header">{x.title}</div>
                                            <div className="about-us-right-side-item-text">{x.text}</div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutUs;