import { useContext } from 'react'
import Hero from "~/components/pages/home/hero";
import { LangContext } from "~/context/lang";
import Head from "next/head";
import OurProducts from '~/components/pages/home/our-products';
import Service from '~/components/pages/home/service';
import AboutUs from '~/components/pages/home/about';
import AskForService from '~/components/pages/home/ask-for-service';
import Video from '~/components/pages/home/video';
import WhyWorking from '~/components/pages/home/why-working';
import Projects from '~/components/pages/home/projects';
import Clients from '~/components/pages/home/clients';
import Testimonies from '~/components/pages/home/testimonies';

let Home = () => {
  let { getLang } = useContext(LangContext);
  let language = getLang('home');


  return (
    <div>
      <Head>
        <title>Coastal Petroleum - {language.home}</title>
      </Head>
      <Hero language={language} />
      <OurProducts language={language} />
      <Service language={language} />
      <AboutUs language={language} />
      <AskForService language={language} />
      <Video language={language} />
      <WhyWorking language={language} />
      {/* <Projects language={language} /> */}
      <Clients language={language} />
      <Testimonies language={language} />

    </div>
  )
}
export default Home;
