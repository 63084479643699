import Slider from "react-slick";

let Testimonies = ({ language }) => {
    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick}
            >
                <img src="/images/testimonies-next.png" alt="testimonies-next" />
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick}
            >
                <img src="/images/testimonies-prev.png" alt="testimonies-prev" />
            </div>
        );
    }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    const testimonieList = [
        {
            id: 1,
            review: "“Lorem ipsum dolor sit amet consectetur, adipisicing elit. Velit eveniet omnis delectus eius alias voluptates culpa accusamus ullam. Alias eligendi quaerat enim provident voluptatibus eveniet laudantium, consequuntur porro sed saepe.“",
            author: "Nagi Abbas",
            position: "CEO",
            image: "testimonie-1.png"
        },
        {
            id: 2,
            review: "“Lorem ipsum dolor sit amet consectetur, adipisicing elit. Velit eveniet omnis delectus eius alias voluptates culpa accusamus ullam. Alias eligendi quaerat enim provident voluptatibus eveniet laudantium, consequuntur porro sed saepe.“",
            author: "Nagi Abbas",
            position: "CEO",
            image: "testimonie-2.png"
        },
    ]
    return (
        <section className="testimonies" id="testimonies">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 m-auto text-center">
                        <div className="section2-title">{language.testimonials}</div>
                        <div className="testimonies-text">{language.testimonialsText}</div>
                    </div>
                </div>
                <div className="row mt-5">

                    <Slider {...settings}>
                        {
                            testimonieList.map((x, y) =>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="testimonie-item">
                                        {/* <div className="testimonie-item-image">
                                            <img src={`/images/testimonies/${x.image}`} alt={"testimonie_" + y} />
                                        </div> */}
                                        <div className="testimonie-item-review">
                                            <div className="row">
                                                <div className="col-lg-9">
                                                    <div className="testimonie-item-review-text">
                                                        {x.review}
                                                    </div>
                                                    <div className="testimonie-item-review-author">
                                                        {x.author}
                                                    </div>
                                                    <div className="testimonie-item-review-position">
                                                        {x.position}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 d-flex align-items-end justify-content-end">
                                                    <img src="/images/testimonie-img.png" alt="testimonie-img" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    </Slider>
                </div>
            </div>
        </section>
    )
}
export default Testimonies;