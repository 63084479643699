import Slider from "react-slick";
import ALink from "~/components/features/alink";

let OurProducts = ({ language }) => {
    var settings = {
        arrows: false,
        dots: true,
        dotsClass: "slick-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: true,
        autoplay:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    const products = [
        {
            icon: 'gas-mixtures.svg',
            title: language.gasMixtures,
            text: language.gasMixturesText
        },
        {
            icon: 'avaiation-gaslin.svg',
            title: language.avaiationGaslin,
            text: language.avaiationGaslinText
        },
        {
            icon: 'jet-fuel.svg',
            title: language.jetFuel,
            text: language.jetFuelText
        },
        {
            icon: 'diesel.svg',
            title: language.diesel,
            text: language.dieselText
        },
        {
            icon: 'petrol.svg',
            title: language.petrol,
            text: language.petrolText
        },
        {
            icon: 'paraffin.svg',
            title: language.paraffin,
            text: language.paraffinText
        },
        {
            icon: 'heavy-fuel.svg',
            title: language.heavyFuel,
            text: language.heavyFuelText
        },
        {
            icon: 'liquefied.svg',
            title: language.liquefiedPetrolium,
            text: language.liquefiedPetroliumText
        },
        {
            icon: 'bitumen.svg',
            title: language.bitumen,
            text: language.bitumenText
        },
    ]
    return (
        <section className="our-products pt-0" id="our-products">
            <div className="container">
                <img className="position-relative" style={{ top: "6rem" }} src="/images/our-products-img.svg" alt="our-products-img" />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">{language.ourProducts}</div>
                        <div className="section-text">{language.ourProductsText}</div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-12 our-products-slider">
                        <Slider {...settings}>
                            {
                                products.map((x, y) =>
                                    <div className="our-products-slider-item" key={y}>
                                        <div className="our-products-slider-item-icon">
                                            <img src={`/images/icons/${x.icon}`} width={60} alt={x.title} />
                                        </div>
                                        <div className="our-products-slider-item-header">
                                            {x.title}
                                        </div>
                                        <div className="our-products-slider-item-text">
                                            {x.text}
                                        </div>
                                        <ALink href="" className="our-products-slider-item-read-more">{language.readMore}</ALink>
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </div>

        </section>
    )
}
export default OurProducts;