import Slider from "react-slick";

let Clients = ({ language }) => {
    var settings = {
        arrows: false,
        dots: true,
        dotsClass: "slick-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    const clients = [
        {
            image: "exxon.jpg"
        },
        {
            image: "chevron.jpg"
        },
        {
            image: "conoco-philips.jpg"
        },
        {
            image: "shell.jpg"
        },
        {
            image: "total.jpg"
        },
    ]
    return (
        <section className="clients" id="clients">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 m-auto">
                        <div className="section-title">{language.ourHappyClients}</div>
                        <div className="section-text">{language.ourHappyClientsText}</div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12 service-slider">
                        <Slider {...settings}>
                            {
                                clients.map((x, y) =>
                                    <div className="client-item" >
                                        <img src={`/images/clients/${x.image}`} alt={"client_" + y} />
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Clients;