import Slider from "react-slick";
import ALink from "~/components/features/alink";
import ServiceItem from "~/components/partials/home/services-item";

let Service = ({ language }) => {

    var settings = {
        arrows: false,
        dots: true,
        dotsClass: "slick-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    const services = [
        {
            id: 1,
            icon: "infrastructure.svg",
            title: language.infrastructure,
            text: language.infrastructureText,
        },
        {
            id: 2,
            icon: "chemicals.svg",
            title: language.chemicals,
            text: language.chemicalsText,
        },
        {
            id: 3,
            icon: "retail.svg",
            title: language.retail,
            text: language.retailText,
        },
        {
            id: 4,
            icon: "lubricants.svg",
            title: language.lubricants,
            text: language.lubricantsText,
        },
    ]
    return (
        <section className="service" id="service">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">{language.serviceDivisions}</div>
                        <div className="section-text">{language.serviceDivisionsText}</div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12 service-slider">
                        <Slider {...settings}>
                            {
                                services.map((x, y) =>
                                    <div className={`col-lg-4 col-sm-12 ${y % 2 ? 'mt-5' : ''}`} key={y}>
                                        <ServiceItem language={language} data={x} />
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>

            </div>
        </section>
    )
}
export default Service;