import ALink from "~/components/features/alink"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import CountUp from 'react-countup';

let Hero = ({ language }) => {
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    var settings = {
        arrows: false,
        dots: true,
        dotsClass: "slick-dots",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true
    };

    const sliders = [
        {
            image: "hero-slider-1.png",
        },
        {
            image: "hero-slider-2.png",
        },
        {
            image: "hero-slider-3.png",
        },
        {
            image: "hero-slider-4.png",
        }
    ]
    return (
        <>
            <section className="hero py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12 d-flex flex-column justify-content-center mt-3">
                            <div className="hero-header">{language.ourPassionFor}</div>
                            <div className="hero-sub-header">{language.ourPassionForText}</div>
                            <div className="hero-text">{language.heroText}</div>
                            <ALink href="/services" className={"btn btn-dark mt-3"}>{language.readMore}</ALink>

                        </div>
                        <div className="col-lg-7 col-sm-12 position-relative mt-3">
                            <Slider {...settings} ref={slider => { sliderRef = slider; }}>
                                {
                                    sliders.map((x, y) =>
                                        <img src={`/images/${x.image}`} key={y} />
                                    )
                                }

                            </Slider>
                            <div className="custom-arrows">
                                <div className="custom-arrows-buttons">
                                    <button type="button" onClick={next}>
                                        <img src="/images/slider-arrow-right.svg" />
                                    </button>
                                    <button type="button" onClick={previous}>
                                        <img src="/images/slider-arrow-left.svg" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-12"></div>
                        <div className="col-lg-3 col-sm-12 p-0">
                            <div className="hero-counts">
                                <div>
                                    <div className="hero-count">
                                        <CountUp end={"20"} decimals={0} decimal="." />
                                    </div>
                                    <div className="hero-count-text">
                                        {language.yearOfExperience}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 p-0">
                            <div className="hero-counts active">
                                <div>
                                    <div className="hero-count">
                                        <CountUp end={"1345"} decimals={0} decimal="." />
                                    </div>
                                    <div className="hero-count-text">
                                        {language.happyClient}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 p-0">
                            <div className="hero-counts">
                                <div>
                                    <div className="hero-count">
                                        <CountUp end={"1049"} decimals={0} decimal="." />
                                    </div>
                                    <div className="hero-count-text">
                                        {language.finishedProjects}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="scroll-mouse">
                    <img src="/images/icons/mouse.svg" width={35} height={57} className="img-fluid" alt="scroll-mouse" />
                </div>
            </section>
        </>
    )
}

export default Hero;