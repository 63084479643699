import { useContext } from 'react';
import { LangContext } from '~/context/lang';

let WhyWorking = () => {
    let { getLang } = useContext(LangContext);
    let language = getLang('home');
    const workingItems = [
        {
            icon: "professionalism.png",
            title: language.professionalism,
            text: language.professionalismText,
        },
        {
            icon: "global-experience.png",
            title: language.globalExperience,
            text: language.globalExperienceText,
        },
        {
            icon: "accuracy.png",
            title: language.accuracyPortability,
            text: language.accuracyPortabilityText,
        },
    ]
    return (
        <section className="why-working" id="why-working">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">{language.whyWorkingTitle}</div>
                    </div>
                </div>
                <div className="row mt-5">
                    {
                        workingItems.map((x, y) =>
                            <div className="col-lg-4 col-sm-12 text-center mb-2">
                                <img src={`/images/icons/${x.icon}`} width={64} alt={x.title} />
                                <div className="my-4 why-working-item-header">
                                    {x.title}
                                </div>
                                <div className="why-working-item-text">
                                    {x.text}
                                </div>
                            </div>

                        )
                    }
                </div>
            </div>
        </section>
    )
}
export default WhyWorking;