import { useState } from "react";

let Video = ({ language }) => {
    const [seeVideo, setSeeVideo] = useState(false);
    return (
        <>
            {
                seeVideo ?
                    <section>
                        <video src="/video/oil_video.mp4" playsInline autoPlay muted loop width={"100%"} height={"720"} />
                    </section>
                    :
                    <section className="video-area" id="video-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 m-auto">
                                    <div className="video-area-title">
                                        {language.videoTitle}
                                    </div>
                                    <div className="video-area-header">
                                        {language.videoHeader}
                                    </div>
                                    <div className="video-area-button" onClick={() => setSeeVideo(true)} >
                                        <img src="/images/icons/video-play.svg" alt="video-play" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            }

        </>
    )
}
export default Video;