import Slider from "react-slick";
import ALink from "~/components/features/alink";

let Projects = ({ language }) => {
    const projectItems = [
        {
            id: 1,
            title: "Mirlando Construction",
            text: "Coastal Petroleum supplies energy by investing in the safe production of oil, and gas and applying low-carbon technology.",
            image: "project-img-1.jpg",
            location: "Beverly Hills, CA",
            url: "/1"
        },
        {
            id: 2,
            title: "Mirlando Construction",
            text: "Coastal Petroleum supplies energy by investing in the safe production of oil, and gas and applying low-carbon technology.",
            image: "project-img-2.jpg",
            location: "Beverly Hills, CA",
            url: "/2"
        },
        {
            id: 3,
            title: "Mirlando Construction",
            text: "Coastal Petroleum supplies energy by investing in the safe production of oil, and gas and applying low-carbon technology.",
            image: "project-img-3.jpg",
            location: "Beverly Hills, CA",
            url: "/3"
        },
        {
            id: 4,
            title: "Mirlando Construction",
            text: "Coastal Petroleum supplies energy by investing in the safe production of oil, and gas and applying low-carbon technology.",
            image: "project-img-2.jpg",
            location: "Beverly Hills, CA",
            url: "/4"
        },
    ]

    var settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    return (
        <section className="projects" id="projects">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 m-auto">
                        <div className="section-title">{language.ourCompanyProjects}</div>
                        <div className="section-text">{language.ourCompanyProjectsText}</div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12 projects-slider">
                        <Slider {...settings}>
                            {
                                projectItems.map((x, y) =>
                                    <div className={`col-lg-4 col-sm-12`} key={y}>
                                        <div className="projects-item">
                                            <div className="projects-item-image">
                                                <img src={`/images/${x.image}`} className="img-fluid" alt={x.title} />
                                                <div className="projects-item-location">
                                                    <img src="/images/icons/location.svg" alt="location" />
                                                    <div>
                                                        <span>{language.location}</span>
                                                        <span className="mx-1">{x.location}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="projects-item-title">
                                                {x.title}
                                            </div>
                                            <div className="projects-item-text">
                                                {x.text}
                                            </div>
                                            {/* <div className="projects-item-readmore">
                                                <ALink href={x.url} target="_blank">
                                                    {language.readMore}
                                                </ALink>
                                            </div> */}

                                        </div>
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Projects;